
  import { Component, Prop } from 'vue-property-decorator'
  import { PersonAccount } from '@/entities/persons'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'

@Component
  export default class ExpandableAccount extends Expandable {
  @Prop({ type: String, default: '' }) path: string

  get basicData (): Array<{
    bankName: string,
    accounts: Array<{ type: string, number: string, holder: string }>
  }> {
    const { value } = this
    const banks = value.filter(v => Boolean(v))
    if (!value?.length || !banks?.length) return []

    return this.groupByBankName(value.map((val: PersonAccount) => {
      const coHolder = val?.bankData?.holders?.find(personAccount => val?.holder?.id !== personAccount?.holder?.id)
      return {
        bankName: val?.bankData?.bank?.alias,
        account: {
          type: val?.bankData?.accountType?.description,
          number: val?.bankData?.accountNumber,
          holder: coHolder ? `${coHolder.holder.name} Rut: ${coHolder.holder.uid}` : '',
        },
      }
    }))
  }

  groupByBankName (bankData): Array<{
    bankName: string,
    accounts: Array<{ type: string, number: string, holder: string }>
  }> {
    const grouped = bankData.reduce((acc, val) => {
      const key = val.bankName
      if (!acc[key]) {
        acc[key] = {
          bankName: key,
          accounts: [],
        }
      }
      acc[key].accounts.push(val.account)
      return acc
    }, {})

    return Object.values(grouped)
  }
  }
